<template>
  <el-dialog title="Nir" :visible.sync="showPopup" :fullscreen="true" append-to-body :show-close="false" :close-on-press-escape="false">
    <el-form
     :status-icon="false"
      label-position="top"
      :inline="false"
      :rules="rules"
      label-width="100%"
      :model="selectedObject"
      ref="my-form"
      @submit.prevent="save"
      v-loading="loadingVisible"
      size="mini"
    >

    <!-- {{this.Info.ProdusePtSelectExistente.length}}+
      <div v-for="linie in this.Info.ProdusePtSelectExistente"> {{linie.Id}} - <pre></pre></div>
      --------
      <div v-for="linie in this.Info.ProdusePtSelect"> {{linie.Nume}}</div> -->
      <el-row :gutter="15">
         <el-col :md="4">
          <el-form-item label="Gestiune" prop="IdGestiune">
           <el-select 
           ref="focusFirst"
           size="mini" class="full-width" filterable
              v-model="selectedObject.IdGestiune"
              @change="handleSelectGestiune()"
            >
            <el-option v-for="item in Info.gestiuni" :key="item.Id + item.Nume" :label="item.Nume" :value="item.Id" />
          </el-select>
          </el-form-item>
        </el-col>        
        <el-col :md="4">
          <el-form-item label="Furnizor" prop="IdFurnizor" size="mini">
            <!-- <div class="input-group full-width"> -->
              <!-- @change="handleSelectFurnizor" -->
              <el-select
              class="full-width"
               
                size="mini"
                v-model="selectedObject.IdFurnizor"
                
                remote
                :remote-method="handleFind"
                filterable
              >
                <el-option
                  v-for="item in Info.furnizori"
                  :key="item.Id + item.CodFiscal"
                  id="furnizor_cif"
                  :name="item.CodFiscal"
                  :label="item.Nume"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
              <!-- <div class="btn-a input-group-append">
                <el-button tabindex="-1" plain size="mini" type="success" icon="el-icon-plus" @click="show_add_new_furnizor"/>
              </div>
            </div> -->
          </el-form-item>
        </el-col>
        <el-col :md="4">
          <el-form-item label="Data document" prop="DataDocument" size="mini">
            <div class="full-width">
              <el-date-picker
                v-model="selectedObject.DataDocument"
                type="date"
                value-format="yyyy-MM-dd"
                format="dd.MM.yyyy"
              />
            </div>
          </el-form-item>
        </el-col>        
        <el-col :md="4">
          <el-form-item label="Data intrare" prop="DataIntrare" size="mini">
            <div class="full-width">
              <el-date-picker
              ref="focusDataIntrare"
                v-model="selectedObject.DataIntrare"
                type="date"
                value-format="yyyy-MM-dd"
                format="dd.MM.yyyy"
                @change="verifica_data_intrare()"
              />
            </div>
          </el-form-item>
        </el-col>        
        <el-col :md="4">
          <el-form-item label="Termen plata" prop="TermenPlata" size="mini">
            <div class="full-width">
              <el-date-picker
                v-model="selectedObject.TermenPlata"
                type="date"
                value-format="yyyy-MM-dd"
                format="dd.MM.yyyy"
                :default-value="null"
              />
            </div>
          </el-form-item>
        </el-col>
        <el-col :md="4">
          <el-form-item label="Nr. Factura" prop="NumarComanda">
            <el-input
              class="full-width"
              v-model="selectedObject.NumarComanda"
            />
          </el-form-item>
        </el-col> 
        <el-col :md="4">
          <el-form-item label="Stoc Comanda" prop="StocComanda">
            <el-input
              class="full-width"
              v-model="selectedObject.StocComanda"
            />
          </el-form-item>
        </el-col>
        <!-- <el-col :md="4">
          <el-form-item label="CIF">
            <el-input
              class="full-width"
              v-model="selectedObject.CifFurnizor"
              :disabled="true"
            />
          </el-form-item>
        </el-col> -->
        <!-- <el-col :md="4">
          <el-form-item label="Reg. Com.">
            <el-input
              class="full-width"
              v-model="selectedObject.RegComFurnizor"
              :disabled="true"
            />
          </el-form-item>
        </el-col> -->
        <el-col :md="4">
          <el-form-item label="Curs" prop="Curs">
            <div class="full-width input-group">
              <el-input-number
                size="mini"
                v-model="selectedObject.Curs"
                :precision="4"
                :min="0"
                controls-position="right"
                :step="0.01"
                @change="handleChangeCurs()"
              />
              <div class="btn-a input-group-append">
                <el-button tabindex="-1" style="margin-right: 16px" plain size="mini" type="primary" class="success" icon="el-icon-cloudy" @click="selecteazaDataCurs()"></el-button>
                <el-date-picker 
                :picker-options="datePickerOptions"
                popper-class="my-popover george"
                style="display: none" 
                ref="refDataCurs"  
                v-model="selectedObject.DataCurs" type="date" value-format="yyyy-MM-dd" format="dd.MM.yyyy" />
              </div>
            </div>
          </el-form-item>
        </el-col>        
        
       
        
        <el-col :md="4">
          <el-form-item label="Cost manipulare RON">
             <el-input-number size="mini" class="full-width" v-model="selectedObject.CostManipulare" 
              :precision="2" :min="0" :step="1"
              @change="totalProduse()"
            />
          </el-form-item>
        </el-col>        
        
        <el-col :md="4">
          <el-form-item label="Cost transport RON">
             <el-input-number size="mini" class="full-width" v-model="selectedObject.CostTransport" 
              :precision="2" :min="0" :step="1"
              @change="totalProduse()"
            />
          </el-form-item>
        </el-col>

      </el-row>
      <el-row>
        <el-col :md="24">
          <el-card shadow="hover" v-show="selectedObject.IdGestiune > 0 && selectedObject.Curs > 0">
            <div slot="header" class="clearfix" style="padding: 0px">
              <span>Produse</span>
            </div>
            <el-table :header-cell-style="{ background: '#393E46' }" :data="selectedObject.Produse" :key="refreshTable" >
              <!-- <el-table-column min-width="160px" label="Produs">
                <template slot-scope="scope">
                    <el-form-item
                      :prop="'Produse[' + scope.$index + '].NumeProdus'"
                      :rules="{ required: true, message: 'Campul este obligatoriu', trigger: 'blur', }"
                    >
                        <el-select 
                        class="full-width"
                          size="mini" filterable v-model="selectedObject.Produse[scope.$index].IdProdus"
                          @change="handleSelectProdus(scope.$index)"
                          :ref="'focusProdus[' + scope.$index + ']'"
                        >
                          <el-option
                            v-for="item in Info.ProdusePtSelect"
                            :key="item.Id"
                            :id="selectedObject.IdGestiune == '' ||
                            selectedObject.IdGestiune == null ||
                            selectedObject.IdGestiune == undefined ? '' : 'produs_cu_stoc'"
                            :name="'Stoc: ' + (item.Intrari - item.Iesiri)"
                            :label="item.Nume"
                            :value="item.Id"
                          >
                          </el-option>
                        </el-select>
                        <div class="btn-a input-group-append">
                          <el-button tabindex="-1" plain size="mini" type="success" icon="el-icon-plus" @click="show_add_new_produs(scope.$index)" />
                        </div>
                    </el-form-item>
                </template>
              </el-table-column> -->
              <el-table-column min-width="190px" label="Produs">
                <template slot-scope="scope">
                  <el-form-item :prop="'Produse[' + scope.$index + '].NumeProdus'"
                    :rules="{ required: true,  message: 'Campul este obligatoriu', trigger: 'blur', }">
                    <div class="input-group full-width">
                      <!-- {{ Info.ProdusePtSelect }}
                      {{ Info.ProdusePtSelect.length }} -->
                      <el-select size="mini" class="produs-input" 
                        remote
                        :remote-method="handleFindProdus"
                        v-model="selectedObject.Produse[scope.$index].IdProdus"
                        @change="handleSelectProdus(scope.$index)" 
                        :ref="'focusProdus[' + scope.$index + ']'"
                        filterable>
                        <el-option v-for="item in Info.ProdusePtSelect" :key="item.Id" :id="selectedObject.IdGestiune == '' ||
                        selectedObject.IdGestiune == null ||
                        selectedObject.IdGestiune == undefined ? '' : 'produs_cu_stoc'"
                          :name="typeof item.StocFinal != 'undefined' ? 'Stoc: ' + (item.StocFinal) : ''" 
                          :label="item.Nume" 
                          :value="item.Id">
                        </el-option>
                      </el-select>
                      <div v-if="selectedObject.Produse[scope.$index].IdProdus != 0" class="btn-a input-group-append">
                        <el-button tabindex="-1" plain size="mini" type="success" icon="el-icon-plus"
                          @click="show_add_new_produs(scope.$index)" class="success"></el-button>
                      </div>
                    </div>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column min-width="80px" label="Cantitate" align="center">
                <template slot-scope="scope">
                    <el-form-item
                     
                      :prop="'Produse[' + scope.$index + '].Cantitate'"
                      :rules="[{required: true, message: 'Campul este obligatoriu', trigger: 'blur' },{ type: 'number', min: 1, message: 'Valoarea minimă este 1', trigger: 'blur' }]"
                    >
                      <el-input-number
                        :ref="'focusCantitate[' + scope.$index + ']'"
                        size="mini" class="full-width"
                        v-model="selectedObject.Produse[scope.$index].Cantitate"
                        :precision="0" controls-position="right" :step="1"
                        @change="totalProduse()"
                      />
                    </el-form-item>
                </template>
              </el-table-column> 
              <el-table-column min-width="70px" label="Cantitate NIR" style="text-align: center;">
                <template slot-scope="scope">
                  <div>
                    <el-form-item
                      :prop="'Produse[' + scope.$index + '].CantitateNir'"
                      :rules="{ required: false, message: 'Campul este obligatoriu', trigger: 'blur',}"
                    >
                      <el-input
                        size="mini" class="full-width"
                        v-model="selectedObject.Produse[scope.$index].CantitateNir"
                        :disabled="true"
                        :precision="0" :min="0" 
                        @change="totalProduse()"
                      />
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column min-width="80px" label="P.U." align="center" >
                <template slot-scope="scope">
                    <el-form-item
                      :prop="'Produse[' + scope.$index + '].PretFaraTVA'"
                      :rules="{ required: true, message: 'Campul este obligatoriu', trigger: 'blur' }"
                    >
                      
                    </el-form-item>
                </template>
              </el-table-column>  -->
              <el-table-column  min-width="80px" label="P.U. Eur" align="center">
                <template slot-scope="scope">
                    <el-form-item
                      :prop="'Produse[' + scope.$index + '].PretFaraTVA'"
                      :rules="{ required: true, message: 'Campul este obligatoriu', trigger: 'blur' }"
                    >
                      <el-input-number
                        :controls=false
                        size="mini" class="full-width"
                        v-model="selectedObject.Produse[scope.$index].PretFaraTVA"
                        :precision="4" controls-position="right" :step="0.0001"
                        @change="totalProduse()"
                      />
                      <!-- <el-input-number
                      v-show="false"
                        size="mini" class="full-width"
                        v-model="selectedObject.Produse[scope.$index].PretFaraTVA"
                        :precision="2" controls-position="right" :step="0.01"
                        @change="totalProduse()"
                      /> -->
                    </el-form-item>
                </template>
              </el-table-column>     
              <el-table-column min-width="50px" label="Disc 1%" align="center">
                <template slot-scope="scope">
                  <el-form-item
                  :prop="'Produse[' + scope.$index + '].Discount1'"
                  :rules="{ required: true, message: 'Campul este obligatoriu', trigger: 'blur' }"
                    >
                      <el-input-number
                        size="mini" class="full-width" :controls=false
                        v-model="selectedObject.Produse[scope.$index].Discount1"
                        :precision="1" controls-position="right" :step="1" :min="0" :max="100"
                        @change="totalProduse()"
                      />
                    </el-form-item>
                </template>
              </el-table-column>  
              <el-table-column min-width="50px" label="Disc 2%" align="center">
                <template slot-scope="scope">
                    <el-form-item
                      :prop="'Produse[' + scope.$index + '].Discount2'"
                      :rules="{ required: true, message: 'Campul este obligatoriu', trigger: 'blur' }"
                    >
                      <el-input-number
                        size="mini" class="full-width" :controls=false
                        v-model="selectedObject.Produse[scope.$index].Discount2"
                        :precision="1" controls-position="right" :step="1" :min="0" :max="100"
                        @change="totalProduse()"
                      />
                    </el-form-item>
                </template>
              </el-table-column>  
              <el-table-column min-width="50px" label="Disc 3%" align="center">
                <template slot-scope="scope">
                    <el-form-item
                      :prop="'Produse[' + scope.$index + '].Discount3'"
                      :rules="{ required: true, message: 'Campul este obligatoriu', trigger: 'blur' }"
                    >
                      <el-input-number
                        size="mini" class="full-width" :controls=false
                        v-model="selectedObject.Produse[scope.$index].Discount3"
                        :precision="1" controls-position="right" :step="1" :min="0" :max="100"
                        @change="totalProduse()"
                      />
                    </el-form-item>
                </template>
              </el-table-column>   
              <el-table-column min-width="53px" label="Manipulare eur" align="right">
               <template slot-scope="scope">  
                  <!-- {{ parseFloat(selectedObject.Produse[scope.$index].ValoareFaraTVA).toFixed(2) }}- -->
                  {{ selectedObject.Produse[scope.$index].CostManipulareProdus | format_money }}
                </template>
              </el-table-column>
              <el-table-column min-width="47px" label="Transport eur" align="right">
                <template slot-scope="scope">
                  {{ selectedObject.Produse[scope.$index].CostTransportProdus | format_money }}
                </template>
              </el-table-column>
              
              <el-table-column min-width="75px" align="right" label="Val Totala Eur" style="text-align: center;">
                <template slot-scope="scope">

                  <!-- {{ selectedObject.Produse[scope.$index].PretFaraTVAEurFinal | format_money('0,0.0000') }} -->
                  {{ (selectedObject.Produse[scope.$index].PretFaraTVAEurFinal*selectedObject.Produse[scope.$index].CantitateNir)  | format_money('0,0.0000') }}
                    <!-- <el-form-item
                    size="mini"
                      :prop="'Produse[' + scope.$index + '].PretFaraTVAEurFinal'"
                      :rules="{ required: true, message: 'Campul este obligatoriu', trigger: 'blur' }"
                    >
                      <el-input-number
                        :controls=false
                        size="mini" class="full-width"
                        v-model="selectedObject.Produse[scope.$index].PretFaraTVAEurFinal"
                        :precision="4" controls-position="right" :step="0.0001"
                        @change="handlePUEurFinal(scope.$index)"
                      />
                    </el-form-item> -->
                </template>
              </el-table-column>              
              <!-- <el-table-column min-width="100px" label="Cota TVA" >
                <template slot-scope="scope">
                    <el-form-item
                      :prop="'Produse[' + scope.$index + '].IdTVA'"
                      :rules="{required: true, message: 'Campul este obligatoriu', trigger: 'blur' }"
                    >
                      <el-select size="mini" class="full-width select_dreapta" v-model="selectedObject.Produse[scope.$index].IdTVA" @change="handleSelectTVA(scope.$index)" >
                        <el-option v-for="item in Info.listaTVA" :key="item.Id" :label="item.Procent" :value="item.Id" />
                      </el-select>
                    </el-form-item>
                </template>
              </el-table-column> -->
              
              <el-table-column  label="" width="40px">
                <template slot-scope="scope">
                  <span>
                    <el-tooltip content="Sterge" :tabindex="-1" >
                      <el-button circle size="mini" type="danger" icon="el-icon-minus" @click="sterge(scope.$index)" />
                    </el-tooltip>
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <el-button @click="rand_nou()"> + </el-button>
            <el-row v-if="selectedObject.arata_total == true">
              <el-col class="tabel-valori">
                <table cellpadding="10" class="tabel-data" style="margin-top: 10px; width: 70%" :key="refreshTotaluri">
                  <tr class="cell-data">
                    <th>Valoare totala fara TVA RON</th>
                    <td align="right" >
                      {{ selectedObject.ValoareFaraTVA * selectedObject.Curs | format_money }}
                    </td>
                  </tr>
                  <tr class="cell-data">
                    <th>Valoare totala fara TVA EUR</th>
                    <td align="right" >
                      {{ (parseFloat(selectedObject.ValoareFaraTVA).toFixed(2) ) | format_money }}
                    </td>
                  </tr>
                  <!-- <tr class="cell-data">
                    <th>Valoare TVA</th>
                    <td style="text-align: right;">
                      {{ selectedObject.ValoareTVA | format_money }}
                    </td>
                  </tr>
                  <tr class="cell-data">
                    <th>Valoare totala cu TVA</th>
                    <td style="text-align: right;">
                      {{ selectedObject.ValoareCuTVA | format_money }}
                    </td>
                  </tr> -->
                </table>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </el-form>
<!-- 
    <pre>


      {{ this.Info.ProdusePtSelect }}
    </pre> -->

    <span slot="footer" class="dialog-footer">
      <el-button @click="resetForm('my-form')"> Renunta </el-button>
      <el-button type="primary" @click="save('0')"> Salveaza </el-button>
      <el-button type="primary" @click="save('1')"> Salveaza si imprima </el-button>
    </span>
    <!-- <pre>{{ this.selectedObject }}</pre> -->
    <Produse-dialog ref="dlg-produs-nou" @save="salvare_produs" />
    <!-- <Furnizori-dialog ref="dlg-furnizor-nou" @save="salvare_furnizor" /> -->
  </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import moment from "moment";
import BasePage from "@/pages/BasePage";
import Produse_dialog from "@/pages/produse/Produse_dialog.vue";
import Furnizori_dialog from "@/pages/furnizori/Furnizori_dialog.vue";

export default {
  name: "Facturi_dialog",
  extends: BasePage,
  components: {
    "Produse-dialog": Produse_dialog,
    "Furnizori-dialog": Furnizori_dialog,
  },
  data() {
    let validateCurs = async (rule, value, callback) => {
      if ( value > 0) {
        callback();
      } else {
        callback(new Error("Cursul trebuie sa fie mai mare ca 0"))
      }
    }
    return {
      datePickerOptions: {
        disabledDate (date) {
            return date > new Date()
        }, 
      },
      refreshTable: 0,
      refreshTotaluri: 0,
      showPopup: false,
      mode: "add",
      selectedObject: {},
      selectedObjectTemplate: {
        CostManipulare: 0,
        CostTransport: 0,
        DataDocument: "",
        DataIntrare: "",
        IdFurnizor: "",
        Curs: "",
        TermenPlata: null,
        IdFurnizor: "",
        CifFurnizor: "",
        RegComFurnizor: "",
        Produse: [],
        arata_total: false,
      },
      produsTemplate: {
        CostManipulareProdus: 0,
        CostTransportProdus: 0,
        IdProdus: "",
        NumeProdus: "",
        Cantitate: "",
        PretFaraTVA: 0,
        PretCuTVA: "",
        PretFaraTVAEur: 0,
        IdTVA: "",
        ProcentTVA: "",
        ValoareTVA: 0,
        ValoareCuTVA: 0,
        ValoareFaraTVA: 0,
        Discount1: 0,
      },
      Info: {
        listaTVA: [],
        gestiuni: [],
        furnizori: [],
        produse: [],
        ProdusePtSelect: [],
        ProdusePtSelectExistente: [],
      },
      rules: {
        Curs: [{ required: true, validator: validateCurs }],
        NumarComanda: [{ required: true, message: "Campul este obligatoriu" }],
        StocComanda: [{ required: true, message: "Campul este obligatoriu" }],
        DataDocument: [{ required: true, message: "Campul este obligatoriu" }],
        DataIntrare: [{ required: true, message: "Campul este obligatoriu" }],
        IdFurnizor: [{ required: true, message: "Campul este obligatoriu" }],
        // CifFurnizor: [{ required: true, message: "Campul este obligatoriu" }],
        // RegComFurnizor: [
        //   { required: true, message: "Campul este obligatoriu" },
        // ],
        PretFaraTVA: [{ required: true, message: "Campul este obligatoriu" }],
        IdGestiune: [{ required: true, message: "Campul este obligatoriu" }],
        IdProdus: [{ required: true, message: "Campul este obligatoriu" }],
        PretFaraTVA: [{ required: true, message: "Campul este obligatoriu" }],
        Cantitate: [{ required: true, message: "Campul este obligatoriu" }, { type: 'number', min: 1, message: 'Valoarea minimă este 1', trigger: 'blur' }],
        IdTVA: [{ required: true, message: "Campul este obligatoriu" }],
      },
    };
  },
  watch: {
    'selectedObject.DataCurs'(newselectedObject, oldselectedObject) {

      // if(typeof oldselectedObject !== 'undefined' && this.selectedObject.DataCurs != null){
      if( this.selectedObject.DataCurs != null){
        this.getCurs(newselectedObject)
      }
    }




  },
  methods: {
    verifica_data_intrare: async function () {
     
      var result = await this.post("luni_blocate/verifica_data_intrare", { data: this.selectedObject.DataIntrare });
      if(result.StatusLuna == 0){
        var data =  moment(this.selectedObject.DataIntrare);
        var mesaj =  'Luna ' + this.$options.filters.LunaLitere(data.format('M')) + ' ' +  data.format('Y') + ' este inchisa!';
        this.$notify({
            title: "Atentie",
            message: mesaj,
            dangerouslyUseHTMLString: true,
            type: "warning",
            duration: 5000,
          })

        this.selectedObject.DataIntrare = '';

        this.$nextTick(()=> {
          this.$refs['focusDataIntrare'].focus();
        })

      }
  
    }, 
    
    show_me: async function (id) {
      this.selectedObject.Produse = [];
      this.Info.ProdusePtSelect = [];
      await this.get_info();
      this.selectedObject = this.selectedObjectTemplate;
      if (id == null) {
        this.mode = "add";
        this.$nextTick(()=> {
          this.$refs['focusFirst'].focus();
        })
      } else {
        this.mode = "edit";
        var result = await this.post("niruri/get_info_item_dialog", { id: id });
        this.selectedObject = result.Item;
        // this.handleSelectGestiune();
        await this.seteazaSelect();

        this.totalProduse();
      }
      this.minim1Produs();
      this.showPopup = true;
    },
    async get_info() {
      var response = await this.post("niruri/get_info_for_dialog");
      this.Info.listaTVA  = response.listaTVA;
      this.Info.gestiuni  = response.gestiuni;
      // this.Info.produse   = response.produse;
      this.Info.furnizori = response.furnizori;
      // this.Info.produseToateGestiunile = response.produseToateGestiunile;
      this.Info.furnizoriOld = [...response.furnizori];
    },

    async seteazaSelect() {
      this.Info.ProdusePtSelectExistente = [];
      this.selectedObject.Produse.forEach((el) => {
          let produs_deja_in_select = this.Info.ProdusePtSelectExistente.filter((xxx) => xxx.Id == el.Id)[0];
          // if (!this.Info.ProdusePtSelectExistente.includes(produs_deja_in_select) ){
          if (!this.Info.ProdusePtSelectExistente.some(item => item.Id === el.IdProdus)) {
            el.Nume = el.NumeProdus;
            el.Id = el.IdProdus;
            this.Info.ProdusePtSelectExistente.push(el);
          }
        });

        // alert(this.Info.ProdusePtSelectExistente.length)
      this.Info.ProdusePtSelect = this.Info.ProdusePtSelectExistente;
      // this.Info.ProdusePtSelect = [...new Set(this.Info.ProdusePtSelectExistente)];
      // alert(this.Info.ProdusePtSelect.length)
      this.Info.ProduseExistentePtSelect = [];
    },

    async getCurs(dataCurs) {
      var response = await this.post("curs_bnr/getCurs", {data: dataCurs})
      if(response.fx){
        this.selectedObject.Curs = response.fx;
      } else {
        delete this.selectedObject.DataCurs;
      }
      this.totalProduse();
    }, 

    totalProduse() {
      if(this.selectedObject.Produse.length > 0){
        var TotalValoareCuTva = [];
        var TotalValoareTva = [];
        var TotalValoareFaraTva = [];
        var TotalCantitateNir = [];


        if (this.selectedObject.CostTransport == undefined) this.selectedObject.CostTransport = 0;
        if (this.selectedObject.CostManipulare == undefined) this.selectedObject.CostManipulare = 0;


        this.selectedObject.Produse.forEach((el) => {

          if(el.Discount1 == undefined) el.Discount1 = 0;
          if(el.Discount2 == undefined) el.Discount2 = 0;
          if(el.Discount3 == undefined) el.Discount3 = 0;
          
          let CantitateAmbalare = 1;
          if(el.CantitateAmbalare > 0) CantitateAmbalare = el.CantitateAmbalare;
          el.CantitateNir = CantitateAmbalare * el.Cantitate;

          el.ValoareFaraTVA = Number(Number(el.PretFaraTVA) * Number(el.CantitateNir));
          el.ValoareTVA =  Number((Number(el.ValoareFaraTVA) * (0 + "." + el.ProcentTVA)));          
          el.ValoareCuTVA = Number(el.ValoareFaraTVA) + Number(el.ValoareTVA);
          // el.PretFaraTVAEur = Number(el.PretFaraTVA / this.selectedObject.Curs)
        
          this.selectedObject.Curs = Number(this.selectedObject.Curs)
          
          el.PretFaraTVAEurFinal = (( Number(el.PretFaraTVA) * (100 - Number(el.Discount1)) / 100) *  (100 - Number(el.Discount2)) / 100 ) *  (100 - Number(el.Discount3)) / 100
          el.ValoareFaraTVA = Number(Number(el.PretFaraTVAEurFinal) * Number(el.CantitateNir));

          TotalValoareFaraTva.push(Number(el.ValoareFaraTVA));
          TotalValoareCuTva.push(parseFloat(el.ValoareCuTVA).toFixed(2));
          TotalValoareTva.push(parseFloat(el.ValoareTVA).toFixed(2));
          TotalCantitateNir.push(parseFloat(el.CantitateNir).toFixed(2));
          
        });

        this.selectedObject.CantitateNirTotala = TotalCantitateNir.reduce((a, b) => Number(a) + Number(b));
        this.selectedObject.ValoareCuTVA = TotalValoareCuTva.reduce((a, b) => Number(a) + Number(b));
        this.selectedObject.ValoareFaraTVA = TotalValoareFaraTva.reduce((a, b) =>  Number(a) + Number(b));
        this.selectedObject.ValoareTVA = TotalValoareTva.reduce((a, b) =>  Number(a) + Number(b));
        this.selectedObject.arata_total = true;


        this.selectedObject.ValoareFaraTVA += this.selectedObject.CostTransport / this.selectedObject.Curs
        this.selectedObject.ValoareFaraTVA += this.selectedObject.CostManipulare / this.selectedObject.Curs

        this.selectedObject.Produse.forEach((el) => {

          el.CostManipulareProdus =  this.selectedObject.CostManipulare / this.selectedObject.Curs  / this.selectedObject.CantitateNirTotala * el.CantitateNir;
          el.CostTransportProdus  =  this.selectedObject.CostTransport / this.selectedObject.Curs  / this.selectedObject.CantitateNirTotala * el.CantitateNir;

          el.PretFaraTVAEurFinal +=  (el.CostManipulareProdus + el.CostTransportProdus)  / el.CantitateNir;
          
        });
        this.selectedObject.Produse.push({})
        this.selectedObject.Produse.pop()
        // this.refreshTable++;
        this.refreshTotaluri++;
      }
    },
    selecteazaDataCurs() { 
      this.$refs['refDataCurs'].pickerVisible = true;
    },

    handleChangeCurs() { 
      delete this.selectedObject.DataCurs;
      this.totalProduse();
    },
    
    minim1Produs() {   
      if(this.selectedObject.Produse.length == 0){
        this.rand_nou()
      }
    },
    rand_nou() {
      var tvaImplicit = this.Info.listaTVA.filter((item) => item.Implicit == 1)[0];
      let nou = {
        Id: "",
        PretFaraTVAEurFinal: 0,
        Cantitate: '',
        Discount1: 0,
        Discount2: 0,
        Discount3: 0,
        PretCuTVA: 0,
        ValoareTva: 0,
        ValoareCuTVA: 0,
        IdTVA: tvaImplicit.Id,
        // ValoareTva: parseFloat(tvaImplicit.Procent).toFixed(2) ,
        ProcentTVA: parseFloat(tvaImplicit.Procent).toFixed(0) ,
      };
      this.selectedObject.Produse.push(nou);
      this.refreshTable++;

      this.$nextTick(()=> {
        let indexNou = this.selectedObject.Produse.length - 1;
        // console.log(this.$refs['focusProdus['+ indexNou +']'])
        this.$refs['focusProdus['+ indexNou +']'].focus()
      })
    },
    async sterge(row) {
      this.$confirm(`Sunteti sigur ?`, "Warning", {
        type: "warning",
      })
        .then(async () => {
          this.Info.ProdusePtSelectExistente.forEach((el, index) => {
            if(this.selectedObject.Produse[row].Id == el.Id){
             if(this.selectedObject.Produse.filter((prod) => prod.Id == el.Id).length == 1){ // sterg doar daca e produsul 1 singura data
              this.Info.ProdusePtSelectExistente.splice(index, 1); // sterg din ProdusePtSelectExistente
              this.Info.ProdusePtSelect = this.Info.ProdusePtSelect.filter((prod) => prod.Id != el.Id); // sterg din ProdusePtSelect
             }
            }
          })

          this.selectedObject.Produse.splice(row, 1);
          this.minim1Produs();
          this.totalProduse();
          this.refreshTable++;
        })
        .catch(() => {
          // this.$message({type: 'info', message: 'Stergere anulata'});
        });
    },
    show_add_new_furnizor() {
      this.$refs["dlg-furnizor-nou"].show_me();
    },
    show_add_new_produs(index) {
      this.$refs["dlg-produs-nou"].show_me(null, index);
    },
    // async salvare_furnizor(insertedObj) {
    //   await this.get_info();
    //   this.selectedObject.IdFurnizor     = insertedObj.Id;
    //   this.selectedObject.NumeFurnizor   = insertedObj.Nume + " " + insertedObj.Prenume;
    //   this.selectedObject.CifFurnizor    = insertedObj.CodFiscal;
    //   this.selectedObject.RegComFurnizor = insertedObj.RegCom;
    // },
    async salvare_produs(insertedObj) {
      await this.get_info();
      this.selectedObject.Produse[insertedObj.numar_linie].IdProdus = String(insertedObj.Id);
      this.selectedObject.Produse[insertedObj.numar_linie].NumeProdus = insertedObj.Nume;
      // this.Info.ProdusePtSelect = this.Info.produseToateGestiunile[this.selectedObject.IdGestiune]; // aici am ramas.
      this.handleSelectProdus(insertedObj.numar_linie);
      this.refreshTable++;
    },
    handleSelectProdus(item) {
      var idProdusSelectat = this.selectedObject.Produse[item].IdProdus;
      if(idProdusSelectat !== "0"){
        let produs = this.Info.ProdusePtSelect.filter((prod) => prod.Id == idProdusSelectat)[0];
        // console.log(this.Info.produse)
        this.selectedObject.Produse[item].NumeProdus        = produs.Nume;
        this.selectedObject.Produse[item].CantitateAmbalare = produs.CantitateAmbalare;

        // } else {
        //   this.selectedObject.Produse[item].NumeProdus = 'Discount';
        //   this.selectedObject.Produse[item].IdGestiune = 0;
        //   this.selectedObject.Produse[item].NumeGestiune = null;
        //   this.selectedObject.Produse[item].Cantitate = 1;


        this.selectedObject.Produse[item].Id                = this.selectedObject.Produse[item].IdProdus
        this.selectedObject.Produse[item].Nume              = this.selectedObject.Produse[item].NumeProdus

        if(this.Info.ProdusePtSelectExistente.filter((rrr) => rrr.Id == idProdusSelectat).length == 0){
          this.Info.ProdusePtSelectExistente.push(produs);
        }
      }

      // console.log(this.$refs['focusCantitate['+item+']'])
        // this.$refs["focusCantitate["+item+"]"].focus()
      this.$nextTick(()=> {
        // console.log(this.$refs['focusCantitate['+item+']'])
        this.$refs['focusCantitate['+item+']'].focus()
        // this.$refs['focusCantitate['+item+']'].focus()
      })

      this.totalProduse();
    },

    handleSelectTVA(item) {
      this.selectedObject.Produse[item].ProcentTVA = this.Info.listaTVA[this.selectedObject.Produse[item].IdTVA - 1].Procent;
      this.totalProduse();
    },
    // handlePUEur(item) {
    //   this.selectedObject.Produse[item].PretFaraTVA = this.selectedObject.Produse[item].PretFaraTVAEur * this.selectedObject.Curs;
    //   this.totalProduse();
    // },

    handlePUEurFinal(item) {

      // let rand = this.selectedObject.Produse[item];

      // let valInainteDe3 = rand.PretFaraTVAEurFinal / (100-rand.Discount3) * 100;
      // let valInainteDe2 = valInainteDe3/ (100-rand.Discount2) * 100;
      // let valInainteDe1 = valInainteDe2/ (100-rand.Discount1) * 100;

      // console.log(typeof rand.Discount1)

      // rand.PretFaraTVAEur = valInainteDe1 ;
      // rand.PretFaraTVA = valInainteDe1 * this.selectedObject.Curs;

      // this.totalProduse();
    },

    async handleSelectGestiune() {
      if(this.Info.ProdusePtSelect.length > 0){
        this.Info.ProdusePtSelect.forEach((el) => {
          delete el.StocFinal;
        })
      }
      this.selectedObject.Produse.splice( this.selectedObject.Produse.length)
    },    

    handleFind(data) {
      this.Info.furnizori = [];

      if(data != "") {
        this.Info.furnizoriOld.forEach(element => {
          if((element.Nume).toLowerCase().indexOf(data) != -1  || (element.CodFiscal != null && (element.CodFiscal).indexOf(data) != -1)) {
            this.Info.furnizori.push(element);
          }
        })
      } else {
        this.Info.furnizori = this.Info.furnizoriOld.slice();
      }
    },
    async  handleFindProdus(data) {
      if (data !== '') {
        await new Promise(resolve => setTimeout(resolve, 200)); 
        this.Info.clienti = [];
        this.loading = true;

        var result = await this.post("produse/remoteFindProdus", { string: data,idGestiune: this.selectedObject.IdGestiune });
        if(typeof result.ProduseFiltrate !== 'undefined'){
          
          // this.Info.ProdusePtSelect o sa contina rezultatul cautarii + produsele deja alese  
          // this.Info.ProdusePtSelect = this.Info.ProdusePtSelectExistente.concat(result.ProduseFiltrate)

          this.Info.ProdusePtSelect = result.ProduseFiltrate;
          this.Info.ProdusePtSelectExistente.forEach((el, index) => {
            // console.log(el)
            if(result.ProduseFiltrate.filter((yyy) => yyy.Id == el.Id).length == 0){
              this.Info.ProdusePtSelect.push(el)
            }
          })
        }

      }
    },

    // handleSelectFurnizor() {
    //   // console.log(idFz)
    //   // console.log(this.Info.furnizori)
    //   var item = this.Info.furnizori[idFz-1]
    //   // console.log(item)
    //   if (parseInt(item.id) < 0) {
    //     this.selectedObject.NumeFurnizor = "";
    //   } else {
    //     var furnizor = item;
    //     this.selectedObject.IdFurnizor = furnizor.Id;
    //     this.selectedObject.CifFurnizor = furnizor.CodFiscal;
    //     this.selectedObject.RegComFurnizor = furnizor.RegCom;
    //     this.selectedObject.NumeFurnizor = furnizor.Nume;
    //   }
    // },   

    save: async function (andPrint) {
      this.$refs["my-form"].validate(async (valid) => {
        if (valid) {
          let response = await this.post("niruri/save", {
            mode: this.mode,
            object: this.selectedObject,
          });
          if(typeof response.Id != 'undefined' && andPrint == 1){
            let IdDePrintat = response.Id; 
            let res = await this.post('niruri/print_niruri', {IdNir: IdDePrintat});

            let doc = window.open("", "_blank"); 
            doc.document.write(res.html);
          }
          this.resetForm();
          this.$emit("save");
        } else console.log("eroare validare formular!");
      });
    },
    resetForm() {
      this.$refs["my-form"].resetFields();
      this.showPopup = false;
    },
    focusChanged (event) {
      const allElements = document.querySelectorAll('*');
      allElements.forEach((element) => {
        element.classList.remove('selectat');
      });

      const el = event.target
      el.classList.add("selectat");
    },
  },
  mounted: function () {
    this.get_info(); 
  },
  created() {
    document.addEventListener('focusin', this.focusChanged)
  },
  beforeDestroy() {
    document.removeEventListener('focusin', this.focusChanged)
  },
};
</script>

<style lang="less" scoped>

#furnizor_cif {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

#furnizor_cif:after {
  content: 'CIF: ' attr(name);
  width: auto;
  max-height: 27px !important;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  font-size: 10px !important;
  padding: 0 5px;
  margin: 2px 0;
  border: 1px solid transparent;
  border-radius: 7px;
  background-color: #40a0ffa1;
}

#produs_cu_stoc:after {
  content: attr(name);
  width: auto;
  max-height: 27px !important;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  font-size: 10px !important;
  padding: 0 5px;
  margin: 2px 0;
  border: 1px solid transparent;
  border-radius: 7px;
  background-color: #40a0ffa1;
}
/deep/ .el-input-number .el-input__inner{ text-align: right;}
// /deep/ .el-input .el-input__inner{ text-align: right;}
/deep/ .is-disabled .el-input__inner{ text-align: right;}
.full-width {
  width: 100% !important;
  .el-input {
    width: 85% !important;
  }
  .el-input-number {
    width: 85% !important;
  }
  .el-select {
    width: 85% !important;
  }

  .el-date-editor.el-input {
    width: 100% !important;
  }

  .btn-a {
    margin: 0;
    padding: 0;
    width: 15% !important;
    .el-button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.el-table .el-form-item {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.tabel-valori {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.tabel-data {
  width: 300px !important;
  word-break: keep-all;
  
}


/deep/ .el-checkbox__input.is-focus{
  border: 3px solid yellow !important;
  border-radius: 5px;
}
/deep/ .selectat{
  background-color: yellow !important;
}

@media only screen and (max-width: 768px) {
  .tabel-data {
    width: 75% !important;
    font-size: 14px;
  }
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
</style>