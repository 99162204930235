<template>
  <el-dialog title="Furnizori" :visible.sync="showPopup" class="my-dialog-class" append-to-body >
    <el-form
      label-position="top"
      :inline="false"
      :rules="rules"
      label-width="100%"
      :model="selectedObject"
      ref="my-form"
      @submit.prevent="save"
      v-loading="loadingVisible"
    >
      <el-row :gutter="15">
        <el-col :md="8">
          <el-form-item prop="Nume" label="Nume">
            <el-input class="full-width" v-model="selectedObject.Nume" />
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item prop="CodFiscal" label="Cod fiscal">
            <div class="input-group full-width">
              <el-input
                @change="handle_cui()"
                class="full-width"
                v-model="selectedObject.CodFiscal"
              />
              <div class="btn-a input-group-append">
                <el-button
                  plain
                  type="success"
                  icon="el-icon-cloudy"
                  @click="interogare_cui()"
                  class="success"
                ></el-button>
              </div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item prop="RegCom" label="Nr registrul comertului">
            <el-input class="full-width" v-model="selectedObject.RegCom" />
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item prop="Cont" label="Cont">
            <el-input class="full-width" v-model="selectedObject.Cont" />
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item prop="Banca" label="Banca">
            <el-input class="full-width" v-model="selectedObject.Banca" />
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item prop="Adresa" label="Adresa">
            <el-input class="full-width" v-model="selectedObject.Adresa" />
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="Descriere" prop="Descriere">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="Descriere"
              v-model="selectedObject.Descriere"
            />
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item>
            <el-switch
              class="dezactivat"
              v-model="selectedObject.Dezactivat"
              active-value="0"
              inactive-value="1"
              active-text="Activ"
              inactive-text="Dezactivat"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showPopup = false"> Renunta </el-button>
      <el-button type="primary" @click="save"> Salveaza </el-button>
    </span>
  </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";

export default {
  name: "Furnizori_dialog",
  extends: BasePage,
  components: {},
  data() {
    return {
      baseUrl: "",
      showPopup: false,
      mode: "add",
      selectedObject: {
        Nume: "",
        CodFiscal: "",
        Adresa: "",
        RegCom: "",
        Cont: "",
        Banca: "",
        Descriere: "",
        Dezactivat: "0",
        cuivalid: false,
      },
      Info: {},
      rules: {
        Nume: [{ required: true, message: "Campul este obligatoriu" }],
        Adresa: [{ required: true, message: "Campul este obligatoriu" }],
      },
    };
  },
  methods: {
    show_me: async function (id) {
      if (id == null) {
        this.mode = "add";
      } else {
        this.mode = "edit";
        var result = await this.post("furnizori/get_info_item_dialog", {
          id: id,
        });
        this.selectedObject = result.Item;
        if (this.selectedObject.CodFiscal != "") {
          this.selectedObject.codvalid = true;
        }
      }
      this.showPopup = true;
    },
    async get_info() {
      var response = await this.post("furnizori/get_info_for_dialog");
      this.Info.categorii = response.categorii;
      this.Info.orase = response.orase;
    },

    handle_cui: async function () {
      if (
        this.selectedObject.CodFiscal == "" ||
        this.selectedObject.CodFiscal.length < 0
      ) {
        this.selectedObject.cuivalid = false;
      }
    },

    interogare_cui: async function () {
      if (this.selectedObject.CodFiscal == "" || this.selectedObject.CodFiscal.length < 0) return this.$message.error('Cod fiscal invalid')
      let args = { CUI: this.selectedObject.CodFiscal };
      let response = await this.post("furnizori/interogare_cui", args);
      let status = JSON.parse(response.CUI);

      if (status.status === 200) {
        let data = status.data;
        let nume = data.nume;
        let adresa = data.adresa;
        let regcom = data.cod_inmatriculare;

        this.selectedObject.Nume = nume;
        this.selectedObject.Adresa = adresa;
        this.selectedObject.RegCom = regcom;

        this.selectedObject.cuivalid = true;
      } else {
        await this.$message.error("Cod fiscal invalid");
      }
    },

    save: async function () {
      this.$refs["my-form"].validate(async (valid) => {
        if (valid) {
          var result = await this.post("furnizori/save", {
            mode: this.mode,
            object: this.selectedObject,
          });
          this.showPopup = false;
          this.selectedObject = {
            Nume: "",
            CodFiscal: "",
            Adresa: "",
            RegCom: "",
            Cont: "",
            Banca: "",
            Descriere: "",
            Dezactivat: "0",
            cuivalid: false,
          };
          this.$emit("save", result.obj);
        }
      });
    },
  },
  mounted: function () {
    this.baseUrl = settings.BASE_URL;
    this.get_info();
  },
};
</script>

<style lang="less" scoped>
.full-width {
  width: 100% !important;
  .el-input {
    width: 85% !important;
  }
  .el-select {
    width: 85% !important;
  }

  .btn-a {
    margin: 0;
    padding: 0;
    width: 15% !important;
    .el-button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>